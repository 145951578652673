import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/curriculums', config);
}
const get = async (code) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/curriculums/' + code);
}
const store = async (data) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    if (data.id) {
        return Api.put('/curriculums/' + data.id, data);
    }
    return Api.post('/curriculums', data);
}
const update = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/curriculums/' + id, formData);
}
const deleteCurriculum = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/curriculums/' + id);
}

const exportExcel = (curriculumId)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/curriculums/slots-excel-export/'+curriculumId,{responseType:"arraybuffer"})
}
const exportExcelAll = (config)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/curriculums/all-slots-excel-export',config)
}

const copy = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/curriculums/'+id+'/copy');
}

export default {
    getAll,
    get,
    store,
    update,
    deleteCurriculum,
    exportExcel,
    copy,
    exportExcelAll
}
