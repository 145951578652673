<template>
    <div>
        <app-layout>
            <template v-slot:header v-if="curriculum_data">
                <Header :title="$t('slots')+' - '+getLocaleText(curriculum_data, 'program_name')"
                        :isFilter="false"
                />
            </template>
            <template v-slot:header-mobile v-if="curriculum_data">
                <HeaderMobile :title="$t('slots')+' - '+getLocaleText(curriculum_data, 'program_name')"
                              :isFilter="false"
                />
            </template>
            <div class="row">
                <div class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4">
                    <div v-if="isLoading === true">
                        <div class="mb-4 no-scrollbar border rounded table-responsive sticky-bar-table"
                             v-for="semester in curriculum_data.program_duration"
                             :key="semester">
                            <div
                                class="col-12 bg-primary text-light font-weight-bold d-flex align-items-center justify-content-between p-2 position-sticky">
                                {{ semester + '. ' + $t('semester') }}
                                <div class="d-flex align-items-center"
                                     v-if="checkPermission('slot_store')"
                                     style="cursor: pointer"
                                     @click="createSlotModel('create',0, semester)">
                                    <i class="ri-add-line h2 mb-0" style="color:white"></i>
                                    <span>{{ $t('add') }}</span>
                                </div>
                            </div>
                            <table v-if="rows[semester]"
                                   role="table"
                                   aria-busy="false"
                                   class="table b-table table-striped table-bordered"
                            >
                                <thead role="rowgroup">
                                <tr role="row">
                                    <th role="columnheader"
                                        scope="col"
                                        aria-sort="descending"
                                        class="table-b-table-default text-center w-40"
                                    >
                                    </th>
                                    <th role="columnheader"
                                        scope="col"
                                        tabindex="0"
                                        aria-sort="descending"
                                        class="table-b-table-default"
                                    >
                                        <div>{{ $t('course_code').toUpper() }}</div>
                                    </th>
                                    <th role="columnheader"
                                        scope="col"
                                        tabindex="0"
                                        aria-sort="descending"
                                        class="table-b-table-default"
                                    >
                                        <div>{{ $t('course_name').toUpper() }}</div>
                                    </th>
                                    <th role="columnheader"
                                        scope="col"
                                        tabindex="0"
                                        aria-sort="descending"
                                        class="table-b-table-default text-center"
                                    >
                                        <div>{{ $t('language').toUpper() }}</div>
                                    </th>
                                    <th role="columnheader"
                                        scope="col"
                                        tabindex="0"
                                        aria-sort="descending"
                                        class="table-b-table-default text-center"
                                    >
                                        <div>{{ $t('slot_type').toUpper() }}</div>
                                    </th>
                                    <th role="columnheader"
                                        scope="col"
                                        tabindex="0"
                                        aria-sort="descending"
                                        class="table-b-table-default text-center"
                                    >
                                        <div>{{ $t('theoretical').toUpper() }}</div>
                                    </th>
                                    <th role="columnheader"
                                        scope="col"
                                        tabindex="0"
                                        aria-sort="descending"
                                        class="table-b-table-default text-center"
                                    >
                                        <div>{{ $t('practical').toUpper() }}</div>
                                    </th>
                                    <th role="columnheader"
                                        scope="col"
                                        tabindex="0"
                                        aria-sort="descending"
                                        class="table-b-table-default text-center"
                                    >
                                        <div>{{ $t('credit').toUpper() }}</div>
                                    </th>
                                    <th role="columnheader"
                                        scope="col"
                                        tabindex="0"
                                        aria-sort="descending"
                                        class="table-b-table-default text-center"
                                    >
                                        <div>{{ $t('ects_credit').toUpper() }}</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody role="rowgroup">
                                <tr role="row" v-for="(item,key) in rows[semester]" :key="key">
                                    <td role="cell" class="align-middle">
                                        <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                                            <template #button-content>
                                                <i class="ri-more-fill"></i>
                                            </template>
                                            <div id="dropdownListHead">
                                                <b-dropdown-item class="d-flex align-items-center"
                                                                 v-if="item.slot_type != 'M'"
                                                                 @click="coursePool(item.pool_id)">
                                                    <i class="ri-window-fill align-middle top-minus-1 mr-3 text-muted"></i>
                                                    {{ $t('show').toUpper() }}
                                                </b-dropdown-item>
                                                <b-dropdown-item
                                                    v-if="checkPermission('slot_update')"
                                                    class="d-flex align-items-center"
                                                    @click="createSlotModel('update', item.id)">
                                                    <i class="ri-pencil-line align-middle top-minus-1 mr-3 text-muted"></i>
                                                    {{ $t('edit').toUpper() }}
                                                </b-dropdown-item>
                                                <b-dropdown-item class="d-flex align-items-center"
                                                                 v-if="checkPermission('slot_delete')"
                                                                 @click="formDelete(item.id)">
                                                    <i class="ri-delete-bin-line align-middle top-minus-1 mr-3 text-muted"></i>
                                                    {{ $t('delete').toUpper() }}
                                                </b-dropdown-item>
                                            </div>
                                        </b-dropdown>
                                    </td>
                                    <td role="cell" class="align-middle">
                                        {{ item.course_code }}
                                    </td>
                                    <td role="cell" class="align-middle">
                                        {{ item.course_name }}
                                    </td>
                                    <td role="cell" class="align-middle text-center">
                                        {{ item.course_language }}
                                    </td>
                                    <td role="cell" class="align-middle text-center">
                                        {{
                                            $i18n.locale == 'tr'
                                                ? item.slot_type_name
                                                : item.slot_type_name_en
                                        }}
                                    </td>
                                    <td role="cell" class="align-middle text-center">
                                        {{ item.theoretical_credit }}
                                    </td>
                                    <td role="cell" class="align-middle text-center">
                                        {{ item.practical_credit }}
                                    </td>
                                    <td role="cell" class="align-middle text-center">{{ item.credit }}</td>
                                    <td role="cell" class="align-middle text-center">
                                        {{ item.ects_credit }}
                                    </td>
                                </tr>
                                <tr role="row">
                                    <td colspan="7" class="text-right"><strong>{{ $t('total') }}</strong></td>
                                    <td class="align-middle text-center"><strong>{{
                                            totalCredits(rows[semester])
                                        }}</strong></td>
                                    <td class="align-middle text-center"><strong>{{
                                            totalAkts(rows[semester])
                                        }}</strong></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="border p-3 d-flex">
                            <div class="col-10 plus">
                                <span class="mr-3"><strong>{{ $t('grand_total') }}:</strong></span>
                            </div>
                            <div class="col-1 credit">
                                <span><strong>{{ totalCredit }}</strong></span>
                            </div>
                            <div class="col-1 akts">
                                <span><strong>{{ totalAkts1 }}</strong></span>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="spinner-border text-light mx-auto d-flex"></div>
                    </div>
                </div>
            </div>
            <CommonModal
                ref="formModal"
                size="md"
                :onHideOnlyX="true"
                :footer="true"
                :footer-class="'justify-content-center'"
            >
                <template v-slot:CommonModalTitle>
                    <div>{{ formId == null ? $t('add_slot') : $t('edit_slot') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="form">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12">
                                    <ValidationProvider
                                        name="slot_type"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('slot_type')">
                                            <slot-type-selectbox
                                                ref="slotType"
                                                v-model="form.slot_type"
                                                :state="errors[0] ? false : null"
                                                @input="changeSlotType($event)"
                                            ></slot-type-selectbox>
                                            <b-form-invalid-feedback
                                                v-if="errors[0]"
                                                v-html="errors[0]"
                                            ></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="w-100" v-if="slotType">
                                    <div v-if="slotType.filter=='course'" class="col-12 col-sm-12 col-md-12">
                                        <ValidationProvider name="course_id" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('course')">
                                                <course-auto-complete
                                                    @input="getCourseCredits"
                                                    v-model="form.course_id"
                                                    :valueObject="{value: form.course_id, text: getLocaleText(form,'course_name')}"
                                                ></course-auto-complete>
                                                <b-form-invalid-feedback
                                                    v-if="errors[0]"
                                                    v-html="errors[0]"
                                                ></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div v-else class="col-12 col-sm-12 col-md-12">
                                        <ValidationProvider
                                            name="pool_id"
                                            rules="required"
                                            v-slot="{ valid, errors }"
                                        >
                                            <b-form-group :label="$t('pool')">
                                                <elective-pool-selectbox
                                                    :type="form.slot_type"
                                                    :programCode="curriculum_data.program_code"
                                                    v-model="form.pool_id"
                                                    :state="errors[0] ? false : null"
                                                ></elective-pool-selectbox>
                                                <b-form-invalid-feedback
                                                    v-if="errors[0]"
                                                    v-html="errors[0]"
                                                ></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-12">
                                        <ValidationProvider
                                            name="semester"
                                            rules="required"
                                            v-slot="{ valid, errors }"
                                        >
                                            <b-form-group :label="$t('semester')">
                                                <semester-numbered-selectbox
                                                    v-model="form.semester"
                                                    :state="errors[0] ? false : null"
                                                ></semester-numbered-selectbox>
                                                <b-form-invalid-feedback
                                                    v-if="errors[0]"
                                                    v-html="errors[0]"
                                                ></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-12">
                                        <ValidationProvider
                                            name="credit"
                                            rules="required"
                                            v-slot="{ valid, errors }"
                                        >
                                            <b-form-group :label="$t('credit')">
                                                <b-form-input
                                                    type="number"
                                                    v-model="form.credit"
                                                    :placeholder="$t('credit')"
                                                    :state="errors[0] ? false : null"
                                                ></b-form-input>
                                                <b-form-invalid-feedback
                                                    v-if="errors[0]"
                                                    v-html="errors[0]"
                                                ></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-12">
                                        <ValidationProvider
                                            name="ects_credit"
                                            rules="required"
                                            v-slot="{ valid, errors }"
                                        >
                                            <b-form-group :label="$t('ects_credit')">
                                                <b-form-input
                                                    type="number"
                                                    v-model="form.ects_credit"
                                                    :placeholder="$t('ects_credit')"
                                                    :state="errors[0] ? false : null"
                                                ></b-form-input>
                                                <b-form-invalid-feedback
                                                    v-if="errors[0]"
                                                    v-html="errors[0]"
                                                ></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-12">
                                        <ValidationProvider
                                            name="apply_to"
                                            rules="required"
                                            v-slot="{ valid, errors }"
                                        >
                                            <b-form-group :label="$t('apply_to')">
                                                <multi-selectbox
                                                    :multiple="false"
                                                    v-model="form.apply_to"
                                                    :state="errors[0] ? false : null"
                                                    :validate-error="errors[0]"
                                                    :options="applyToOptions"
                                                ></multi-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-12" v-if="form.apply_to=='student_numbers'">
                                        <ValidationProvider name="number" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('student_numbers')">
                                                <lined-textarea v-model="form.student_numbers"
                                                                :nowrap="false"
                                                                :disabled="false"
                                                                :styles="{ height: '5em', resize: 'both' }"
                                                                :validateError="errors[0]">
                                                </lined-textarea>
                                                <div class="invalid-feedback d-block" v-if="errors[0]"
                                                     v-html="errors[0]"></div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                </template>
                <template v-slot:CommonModalFooter>
                    <b-form-group>
                        <b-button
                            variant="primary"
                            @click="formCreate"
                            v-if="formProcess == 'create'"
                        >{{ $t('save') }}
                        </b-button
                        >
                        <b-button
                            variant="primary"
                            @click="formUpdate"
                            v-if="formProcess == 'update'"
                        >{{ $t('update') }}
                        </b-button
                        >
                    </b-form-group>
                </template>
            </CommonModal>
            <CommonModal size="xl" ref="coursesModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('slots').toUpper() + ' ' + $t('courses') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <courses :id="selectedPoolId" @hide="closeCoursesModal"></courses>
                    </div>
                </template>
            </CommonModal>
            <CommonModal size="md" id="deleteModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('delete') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="deleteForm">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12">
                                    <ValidationProvider
                                        name="apply_to"
                                        rules="required"
                                        v-slot="{ valid, errors }"
                                    >
                                        <b-form-group :label="$t('apply_to')">
                                            <multi-selectbox
                                                :multiple="false"
                                                v-model="deleteForm.apply_to"
                                                :state="errors[0] ? false : null"
                                                :validate-error="errors[0]"
                                                :options="applyToOptions"
                                            ></multi-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12 col-sm-12 col-md-12" v-if="deleteForm.apply_to=='student_numbers'">
                                    <ValidationProvider name="number" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('student_numbers')">
                                            <lined-textarea v-model="deleteForm.student_numbers"
                                                            :nowrap="false"
                                                            :disabled="false"
                                                            :styles="{ height: '5em', resize: 'both' }"
                                                            :validateError="errors[0]">
                                            </lined-textarea>
                                            <div class="invalid-feedback d-block" v-if="errors[0]"
                                                 v-html="errors[0]"></div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12">
                                    <b-button
                                        variant="danger"
                                        @click="deleteSlot"
                                    >{{ $t('delete') }}
                                    </b-button>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header';
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile';

import {ValidationObserver, ValidationProvider} from 'vee-validate';
import SlotService from '@/services/SlotService';
import SlotTypeSelectbox from '@/components/interactive-fields/SlotTypeSelectbox';
import CommonModal from '@/components/elements/CommonModal';
import ElectivePoolSelectbox from '@/components/interactive-fields/ElectivePoolSelectbox';
import SemesterNumberedSelectbox from '@/components/interactive-fields/SemesterNumberedSelectbox';
import CurriculumService from '@/services/CurriculumService';
import CourseAutoComplete from '@/components/interactive-fields/CourseAutoComplete';
import Courses from '@/modules/electivePools/pages/Courses'
import CourseService from '@/services/CourseService';
import LinedTextarea from "@/components/elements/LinedTextarea.vue";
import {browser} from "vue-pdf-app/dist/vue-pdf-app.common";

export default {
    components: {
        LinedTextarea,
        CourseAutoComplete,
        AppLayout,
        Header,
        HeaderMobile,

        ValidationProvider,
        ValidationObserver,
        SlotTypeSelectbox,
        CommonModal,
        ElectivePoolSelectbox,
        SemesterNumberedSelectbox,
        Courses
    },
    data() {
        return {
            id: null,
            curriculum_id: this.$route.params.id,
            curriculum_data: null,
            showTable: false,
            isLoading: false,
            slotType: null,
            credit: [],
            rows: [],
            form: {},
            deleteForm: {},
            formProcess: null,
            formId: null,
            slot_type: null,
            selectedPoolId: null,
            applyToOptions: [
                {value: 'none', text: this.$t('none_of_students')},
                {value: 'all', text: this.$t('all_students')},
                {value: 'new', text: this.$t('new_students')},
                {value: 'student_numbers', text: this.$t('student_numbers')},
            ],
            deleteId: null
        };
    },
    metaInfo() {
        return {
            title: this.$t('slots')
        };
    },
    computed: {
        totalCredit() {
            let credit = 0
            for (let i in this.rows) {
                this.rows[i].forEach(itm => {
                    credit += itm.credit
                })
            }
            return credit
        },
        totalAkts1(){
            let akts=0
            for (let i in this.rows) {
                this.rows[i].forEach(itm => {
                    akts += itm.ects_credit
                })
            }
            return akts
        }
    },
    watch: {
        'form.slot_type': function (selected) {
            this.slotType = this.$refs.slotType.getItem(selected);
        }
    },
    methods: {
        totalCredits(item) {
            let credit = 0
            item.forEach(itm => {
                credit += itm.credit
            })
            return credit
        },
        totalAkts(item) {
            let akts = 0
            item.forEach(itm => {
                akts += itm.ects_credit
            })
            return akts
        },
        coursePool(pool_id) {
            if (this.checkPermission('electivecourse_index')) {
                this.selectedPoolId = pool_id
                this.$refs.coursesModal.$refs.commonModal.show()
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },

        closeCoursesModal() {
            this.$refs.coursesModal.$refs.commonModal.hide()
        },

        getCurriculumData() {
            return CurriculumService.get(this.curriculum_id)
                .then((response) => {
                    this.curriculum_data = response.data.data;
                    this.getRows();
                });
        },

        getRows() {
            this.showTable = true;
            this.isLoading = false;

            return SlotService.getById(this.curriculum_id)
                .then((response) => {
                    this.isLoading = true;

                    this.rows = this.groupBy(response.data.data, 'semester');
                })
                .finally(() => {
                    this.isLoading = true;
                });
        },
        createSlotModel(process, id = null, semester = null) {
            this.formProcess = process;
            this.formId = null;

            this.formClear();
            this.form.semester = semester;
            this.$refs.form.reset();
            if (this.formProcess == 'update' && id) {
                SlotService.get(id)
                    .then((response) => {
                        let data = response.data.data;
                        this.form = data;
                        this.slot_type = {
                            code: data.slot_type,
                            filter: data.slot_type_filter
                        };
                        this.formId = id;
                    })
                    .catch((e) => {
                        this.showErrors(e);
                    });
            }
            this.$refs.formModal.$refs.commonModal.show();
        },

        changeSlotType(event) {
            if (!event) {
                return;
            }
            this.slot_type = this.form.slot_type;
        },

        // Clear
        formClear() {
            this.form = {};
            this.slot_type = null;
        },

        // Create
        async formCreate() {
            if (this.checkPermission('slot_store')) {
                const isValid = await this.$refs.form.validate();
                if (isValid) {
                    let formData = {
                        curriculum_id: this.curriculum_id,
                        ...this.form
                    };
                    if (this.form.student_numbers) {
                        formData.student_numbers = formData.student_numbers.split(/\n/);
                    }
                    SlotService.store(formData)
                        .then((response) => {
                            this.showMessage(response);
                            this.getRows();
                            this.formClear();
                            this.$refs.form.reset();
                            this.$refs.formModal.$refs.commonModal.hide();
                        })
                        .catch((e) => {
                            this.showErrors(e);
                        });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },

        // Update
        async formUpdate() {
            if (this.checkPermission('slot_update')) {
                const isValid = await this.$refs.form.validate();
                if (isValid && this.formId) {
                    let formData = {
                        ...this.form
                    };
                    if (this.form.student_numbers) {
                        formData.student_numbers = formData.student_numbers.split(/\n/);
                    }
                    SlotService.update(this.formId, formData)
                        .then((response) => {
                            this.showMessage(response);
                            this.getRows();
                            this.formClear();
                            this.$refs.form.reset();
                            this.$refs.formModal.$refs.commonModal.hide();
                        })
                        .catch((e) => {
                            this.showErrors(e);
                        });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },

        formDelete(id) {
            this.deleteId = id;
            this.$bvModal.show('deleteModal');
        },

        // Delete
        async deleteSlot() {
            const isValid = await this.$refs.deleteForm.validate();
            if (!isValid) {
                return;
            }

            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let formData = {...this.deleteForm};
                        if (formData.student_numbers) {
                            formData.student_numbers = formData.student_numbers.split(/\n/);
                        }
                        SlotService.del(this.deleteId, formData)
                            .then((response) => {
                                this.showMessage(response);
                                this.getRows();
                                this.deleteId = null;
                                this.$bvModal.hide('deleteModal');
                            })
                            .catch((error) => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            });
                    }
                });
        },

        getCourseCredits(record) {
            CourseService.get(record).then(response => {
                let data = response.data.data
                this.form.credit = data.credit
                this.form.ects_credit = data.ects_credit
            }).catch(e => {
            })
        },

        // Errors
        showErrors(e) {
            if (e.status == '422') {
                if (e.data.errors.slot_type) {
                    this.$refs.form.errors.slot_type.push(e.data.errors.slot_type[0]);
                }
                if (e.data.errors.pool_id) {
                    this.$refs.form.errors.pool_id.push(e.data.errors.pool_id[0]);
                }
                if (e.data.errors.semester) {
                    this.$refs.form.errors.semester.push(e.data.errors.semester[0]);
                }
                if (e.data.errors.ects_credit) {
                    this.$refs.form.errors.ects_credit.push(e.data.errors.ects_credit[0]);
                }
            } else if (e.status == '406') {
                this.$toast.error(this.$t('api.' + e.data.message));
            }
        }
    },

    created() {
        this.getCurriculumData();
    }
};
</script>
<style scoped>
.plus {
    display: flex;
    justify-content: flex-end;

}

.credit {
    display: flex;
    justify-content: center;
    align-items: center;
}

.akts {
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>
